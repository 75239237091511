import Vue from 'vue'

import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import {App, plugin} from '@inertiajs/inertia-vue'
import {InertiaProgress} from '@inertiajs/progress/src'
import UUID from 'vue-uuid'
import InApp from 'detect-inapp'
import VueGeolocationApi from 'vue-geolocation-api'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'

import Vuetify from 'vuetify'
import en from 'vuetify/lib/locale/en'
import th from 'vuetify/lib/locale/th'

import VuetifyConfirm from 'vuetify-confirm'

import {TiptapVuetifyPlugin} from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.use(VueMeta)
Vue.use(PortalVue)
Vue.use(plugin)

InertiaProgress.init()
Vue.use(UUID)
Vue.use(VueGeolocationApi)

// import VueQuillEditor from 'vue-quill-editor/dist/ssr'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor)

// import VueCoreVideoPlayer from 'vue-core-video-player'
// import HLSCore from '@core-player/playcore-hls'
// Vue.use(VueCoreVideoPlayer)

// import VideoPlayer from 'vue-vjs-hls'
// VideoPlayer.config({
//     youtube: true,  // default false
//     switcher: true, // default true
//     hls: true       // default true
// })
// Vue.use(VideoPlayer)
// import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)
const vuetify = new Vuetify({
  lang: {
    locales: { en, th},
    current: 'th',
  },
})

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'ยืนยัน',
  buttonFalseText: 'ยกเลิก',
})

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})

// import Editor from 'vue-editor-js'
// Vue.use(Editor)

Vue.config.productionTip = false

Vue.prototype.$dayjs = dayjs
Vue.prototype.$dayjs.locale({
  name: 'th',
  weekdays: 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัสบดี_ศุกร์_เสาร์'.split('_'),
  weekdaysShort: 'อาทิตย์_จันทร์_อังคาร_พุธ_พฤหัส_ศุกร์_เสาร์'.split('_'),
  weekdaysMin: 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
  months: 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split('_'),
  monthsShort: 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
  formats: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD/MM/BB',
    LL: 'D MMMM BBBB',
    LLL: 'D MMMM BBBB เวลา H:mm',
    LLLL: 'วันddddที่ D MMMM พ.ศ. BBBB เวลา H:mm',
  },
  relativeTime: {
    future: 'อีก %s',
    past: '%sที่แล้ว',
    s: 'ไม่กี่วินาที',
    m: '1 นาที',
    mm: '%d นาที',
    h: '1 ชั่วโมง',
    hh: '%d ชั่วโมง',
    d: '1 วัน',
    dd: '%d วัน',
    M: '1 เดือน',
    MM: '%d เดือน',
    y: '1 ปี',
    yy: '%d ปี',
  },
  ordinal: function ordinal(n) {
    return n + '.'
  },
})
Vue.prototype.$dayjs.extend(buddhistEra)
Vue.prototype.$dayjs.extend(localizedFormat)
Vue.prototype.$dayjs.extend(isBetween)
Vue.prototype.$dayjs.extend(isToday)
Vue.prototype.$inApp = new InApp(navigator.userAgent || navigator.vendor || window.opera)
Vue.prototype.$geolocation = navigator.geolocation
Vue.prototype.route = window.route

Vue.mixin({
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    // route: window.route,
    // $dayjs: $dayjs,
    back: () => {
      window.history.back()
    },
    redirectTo: (url) => {
      window.location.replace(url)
      // window.location.href = url
    },
    openNew: (url) => {
      window.open(url, '_blank')
      // window.location.href = url
    },
  },
})

const el = document.getElementById('app')

new Vue({
  metaInfo: {
    titleTemplate: title => (title ? `${title} - Care Giver Learning Assistant` : 'Care Giver Learning Assistant'),
  },
  vuetify: vuetify,
  mounted() {
    if(this.$geolocation){
      this.$geolocation.getCurrentPosition((position) => {
        console.log('Latitude : ' + position.coords.latitude)
        console.log('Longitude: ' + position.coords.longitude)
      })}
    else
      console.error('Geolocation is not supported by this browser.')
  },
  render: h =>
    h(App, {
      props: {
        initialPage: JSON.parse(el.dataset.page),
        resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
      },
    }),
}).$mount(el)
